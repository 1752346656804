/**
 * Created by andrey on 29.05.17.
 */

var LevelViewEditor = LevelView.extend({
    onPressed: function() {
        LevelManager.getInstance().setLevel({
            episodeNo: this.level.episodeNo,
            levelNo: this.level.levelNo
        });

        LevelManager.getInstance().editLevel();
    }
});

LevelViewEditor.generateLevelId = LevelView.generateLevelId;